<template>
  <div>
    <section>
      <div class="grid uppercase" v-if="$screen.lg">
        <div
          v-for="item in headings"
          :key="item.idx"
          class="align-center d-col py-15 lightBg text-center"
        >
          <s-icon height="21" color="grey">{{ item.icon }}</s-icon>
          <div class="opacity-60 font-12 pt-10 hide_on_mobile">
            {{ item.title }}
          </div>
        </div>
      </div>
      <hr class="hide" />
    </section>
    <section class="pt-10 table" v-if="getVisibleLength">
      <div v-for="item in filterSearch" :key="item.id" class="row">
        <div v-if="!$screen.lg">
          <div
            v-for="heading in headings"
            :key="heading.idx"
            :title="heading.title"
          >
            <div v-if="!item.isDeleted" class="grid uppercase pb-1">
              <div class="align-center d-col headingIcon lightBg center">
                <s-icon height="21" color="grey">{{ heading.icon }}</s-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="grid grow" v-if="!item.isDeleted">
          <div class="item">
            {{ item.user.lastName }} {{ item.user.firstName }}
          </div>
          <div class="item">
            {{ item.invoice.invoiceNumber }}
          </div>
          <div class="item">
            {{ format(item.license.createdAt) }}
          </div>
          <div class="item">
            {{ item.invoice.items[0].name }}
          </div>
          <div class="item">
            {{ getStatus(item) }}
          </div>
          <div class="item">{{ formatCurrency(item.invoice.priceTotal) }}</div>
          <div class="item">
            {{ sentenseCase(item.license.product.billingType) }}
          </div>
          <div class="item">
            {{ item.license.isActive ? "Active" : "Inactive" }}
          </div>
          <div class="d-flex">
            <button
              @click="view(item)"
              :title="'View report'"
              class="item grow mr-1 darkBg center pointer hover"
            >
              <s-icon color="grey">eye-outline</s-icon>
            </button>
          </div>
        </div>
      </div>
    </section>
    <section v-if="!getVisibleLength" class="pa-40 shade4 center">
      <span class="weight-300">No results to display</span>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
export default {
  props: ["search"],
  data() {
    return {
      filtedItems: [],
      pickerID: -1,
      selected: {},
    };
  },
  methods: {
    view(val) {
      this.$store.commit("payment/saleReport", val);
      this.$router.push("/partners/sales/view");
    },
    formatCurrency(x) {
      x = Number(x).toFixed(2);
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return "$ " + parts.join(".");
    },
    format(val) {
      return dayjs(val).format("DD MMM YY");
    },
    getStatus(item) {
      let msg = "Not Active";
      if (item.license.isExpired) msg = "Expired";
      if (item.license.isDeleted) msg = "Cancelled";
      if (item.invoice.isPaid) msg = "Active";
      if (item.license.isActive) msg = "Active";
      return msg;
    },
    sentenseCase(str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
  },
  computed: {
    getVisibleLength() {
      let data = this.filterSearch;
      if (!Array.isArray(data)) return;
      return data.filter((val) => val.isDeleted !== true).length;
    },
    filterSearch() {
      if (!this.report) return;
      return this.report.filter((val) => {
        val.price = String(val.price);
        if (!val.price) val.price = "";
        if (!val.billingType) val.billingType = "";
        if (!val.name) val.name = "";
        let term = this.search.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
        let price = val.price.toLowerCase().search(term.toLowerCase()) !== -1;
        let billingType =
          val.billingType.toLowerCase().search(term.toLowerCase()) !== -1;
        let name = val.name.toLowerCase().search(term.toLowerCase()) !== -1;
        return price || billingType || name;
      });
    },
    ...mapState("payment", ["report"]),
    headings() {
      return [
        { icon: "account-outline", title: "Customer Name" },
        { icon: "numeric-1-box-multiple-outline", title: "Order Number" },
        { icon: "calendar", title: "Order Date" },
        { icon: "file-document-outline", title: "Product" },
        { icon: "list-status", title: "Status" },
        { icon: "tally-mark-5", title: "Amount" },
        { icon: "calendar-multiple", title: "Annual/Monthly" },
        { icon: "checkbox-marked-circle-outline", title: "Active (Paid)" },
         {
          icon: "cursor-default-click-outline",
          title: this.$t("attendees.actions"),
        },
      ];
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  max-height: calc(100vh - 430px);

  overflow-y: auto;
  height: 100%;
  padding: 10px 0px;
  box-sizing: border-box;
}

.grid {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-column-gap: 2px;
}

.item {
  display: flex;
  align-items: center;
  color: rgb(180, 180, 180);
  border-bottom: 1px solid #42515e;
  min-height: 36px;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.35);
  padding: 8px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hover:hover {
  background-color: rgba(0, 0, 0, 0.45);
}

.box {
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  background-color: #151b23;
  margin-left: 2px;
}

.colorBox {
  min-width: 20px;
  width: 20px;
  background-color: #151b23;
  margin-right: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.rotate {
  transform: translate(4px, 8px) rotate(45deg);
  opacity: 0;
}

.rotate:hover {
  opacity: 1;
}

.darkBg {
  background-color: rgba(0, 0, 0, 0.35);
}

.lightBg {
  background-color: rgba(0, 0, 0, 0.15);
}

.headingIcon {
  height: 40px;
  width: 40px;
  aspect-ratio: 1/1;
}

button:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

@media only screen and (max-width: 991px) {
  .grid {
    display: flex;
    flex-direction: column;
  }

  .table {
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-height: calc(100vh - 334px - var(--footer-height));
  }

  .hide {
    display: none;
  }

  .item {
    min-height: 41px;
  }
}

@media only screen and (max-width: 576px) {
  .grid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 80px;
  }

  .table {
    max-height: calc(100vh - 333px);
  }
}
</style>