<template>
  <div class="page_container">
    <section>
      <div class="row">
        <div class="pt-35 pb-5">
          <h1 class="my-0">Sales Report</h1>
        </div>
      </div>
      <hr class="mt-15" />
      <section class="d-flex py-15 space-between align-center">
        <div class="d-flex searchBar" :title="'Start typing to search for a report'">
          <s-text-field v-model="search" ref="search" :placeholder="$t('attendees.search')" block></s-text-field>
          <div class="box center pointer" @click="focus()">
            <s-icon color="grey" class="">account-search-outline</s-icon>
          </div>
        </div>
        <s-btn @click.native="download" class="green addButton ml-10" :title="'Add a user under your management'">
          <s-icon width="21" class="pr-10" color="white">download</s-icon>
          <div class="grow">
            <div>Download</div>
          </div>
        </s-btn>
      </section>
      <div class="row gap-10 mb-10">
        <s-dropdown key="1" v-model="month" :countries="months" placeholder="Month"></s-dropdown>
        <s-dropdown key="2" v-model="year" :countries="years" placeholder="Year"></s-dropdown>
      </div>
      <hr />
    </section>
    <Table :search="search" />
  </div>
</template>

<script>
import { mapState } from "vuex";
const { Parser } = require("json2csv");
import Table from "./comp/SalesTable.vue";
import dayjs from "dayjs";
export default {
  components: {
    Table,
  },
  data() {
    return {
      search: "",
      month: "",
      year: "",
    };
  },
  methods: {
    focus() {
      this.$refs.search.onFocus();
    },
    async post() {
      let data = { year: this.year, month: this.month };
      return await this.$store.dispatch("payment/getSalesReport", data);
    },
    async download() {
      const res = await this.post();
      if (res.length == 0) return this.notifyEmpty();
      let formatted = this.formatJson(res)
      const json2csvParser = new Parser();
      const csv = json2csvParser.parse(formatted);
      const el = document.createElement("a");
      el.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      el.target = "_blank";
      el.download = `SecurMeet Sales Report ${this.year} ${this.month}`;
      el.click();
    },
    notifyEmpty() {
      this.$store.commit("notifications/push", {
        error: "There are no sales in the report for the selected month",
      });
    },
    formatJson(items) {
      return items.map(item => {
        return {
          'CUSTOMER NAME': `${item.user.lastName}  ${item.user.firstName}`,
          "ORDER NUMBER": item.invoice.invoiceNumber,
          "ORDER DATE": this.format(item.license.createdAt),
          "PRODUCT": item.invoice.items[0].name,
          "STATUS": this.getStatus(item),
          "AMOUNT": this.formatCurrency(item.invoice.priceTotal),
          "ANNUAL/MONTHLY": this.sentenseCase(item.license.product.billingType),
          "ACTIVE (PAID)": item.license.isActive ? "Active" : "Inactive",
        }
      })
    },
    formatCurrency(x) {
      x = Number(x).toFixed(2);
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return "$ " + parts.join(".");
    },
    format(val) {
      return dayjs(val).format("DD MMM YY");
    },
    getStatus(item) {
      let msg = "Not Active";
      if (item.license.isExpired) msg = "Expired";
      if (item.license.isDeleted) msg = "Cancelled";
      if (item.invoice.isPaid) msg = "Active";
      if (item.license.isActive) msg = "Active";
      return msg;
    },
    sentenseCase(str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
  },
  computed: {
    months() {
      let items = [];
      let date = dayjs().month(0);
      for (let x = 0; x < 12; x++) {
        items.push({ name: date.format("MMMM"), id: x });
        date = date.add(1, "month");
      }
      return items;
    },
    years() {
      let items = [];
      let date = dayjs();
      for (let x = 0; x < 1; x++) {
        let year = date.format("YYYY");
        items.push({ name: year, id: year });
        date = date.subtract(1, "year");
      }
      return items;
    },
    ...mapState("user", ["firstName", "lastName", "tip"]),
  },
  watch: {
    month() {
      this.post();
    },
    year() {
      this.post();
    },
  },
  async mounted() {
    this.$store.dispatch("products/getResellerProducts");
    this.year = dayjs().year();
    this.month = dayjs().month()
  },
};
</script>

<style lang="less" scoped>
.table {
  max-height: calc(100vh - 411px);
  overflow-y: auto;
  height: 100%;
  padding: 10px 0px;
  box-sizing: border-box;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 110px;
  grid-column-gap: 2px;
  width: 100%;
  transition: 0.2s all ease;
}

.grid:hover {
  background-color: rgba(0, 0, 0, 0.45);
}

.details_grid {
  display: grid;
  grid-template-columns: 160px 160px;
  max-height: 200px;
  grid-row-gap: 5px;
}

.item {
  color: rgb(180, 180, 180);
  border-bottom: 1px solid #42515e;
  min-height: 36px;
  font-size: 16px;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
  padding: 8px;
  box-sizing: border-box;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hover:hover {
  background-color: rgba(0, 0, 0, 0.45);
}

.admin {
  font-size: 12px;
  color: #53bdc1;
}

.box {
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  background-color: #151b23;
  margin-left: 2px;
}

.colorBox {
  min-width: 20px;
  width: 20px;
  background-color: #151b23;
  margin-right: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.searchBar {
  max-width: 480px;
  width: 100%;
}

.rotate {
  transform: translate(4px, 8px) rotate(45deg);
  opacity: 0;
}

.rotate:hover {
  opacity: 1;
}

.addButton {
  min-width: min-content;
  margin-left: 15px;
}

.darkBg {
  background-color: rgba(0, 0, 0, 0.35);
}

.lightBg {
  background-color: rgba(0, 0, 0, 0.15);
}

.min-38 {
  min-height: 38px;
}

@media only screen and (max-width: 576px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
</style>